import React from 'react';

interface LeadingBenefitsSectionProps {
  lead: string;
  title: string;
  tiles: {
    icon: {
      sourceUrl: string;
      altText: string;
    };
    title: string;
    body: string;
  }[];
}

const LeadingBenefitsSection: React.FC<LeadingBenefitsSectionProps> = ({
  lead,
  title,
  tiles,
}) => (
  <section className="py-20 bg-tertiary lg:py-32">
    <div className="u-container">
      <div className="max-w-2xl">
        <div className="u-s1">{lead}</div>
        <h2 className="u-h2 mb-15">{title}</h2>
      </div>

      <div className="justify-between space-y-5 lg:flex lg:space-y-0 lg:space-x-5">
        {tiles.map((tile, index) => (
          <div
            key={index}
            className="flex-1 bg-white rounded-md shadow-card py-13 px-11"
          >
            <img
              src={tile.icon.sourceUrl}
              alt={tile.icon.altText}
              className="w-auto h-9 mb-15"
            />
            <h3 className="u-h5 mb-2.5">{tile.title}</h3>
            <p className="text-sm leading-6 text-body-copy">{tile.body}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default LeadingBenefitsSection;
