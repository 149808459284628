import React from 'react';
import TwitterIcon from '../atoms/TwitterIcon';

interface TwitterHeroProps {
  title: string;
  body: string;
}

const TwitterHero: React.FC<TwitterHeroProps> = ({ title, body }) => (
  <section
    className="relative flex flex-col items-center py-20 overflow-hidden text-center text-white bg-cover md:py-40"
    style={{ backgroundImage: 'url(/images/twitter-bg.jpg)' }}
  >
    <img
      src="/images/twitter-ribbon-top-left.png"
      alt=""
      className="absolute top-0 left-0 w-auto h-32 md:h-44 xl:h-52"
    />
    <img
      src="/images/twitter-ribbon-bottom-left.png"
      alt=""
      className="absolute bottom-0 left-0 w-auto h-40 md:h-64 lg:h-96 xl:h-120"
    />
    <img
      src="/images/twitter-ribbon-top-right.png"
      alt=""
      className="absolute top-0 right-0 w-40 h-auto md:w-64 lg:w-96 xl:w-120"
    />
    <TwitterIcon className="w-12 h-auto mb-10" />
    <h1 className="max-w-4xl mx-auto mb-8 u-h1">
      {title}
    </h1>
    <p className="md:text-xl">{body}</p>
  </section>
);

export default TwitterHero;
