import React from 'react';
import { IImage } from '../../interfaces/image';
import GetInTouchForm from '../molecules/GetInTouchForm';

interface GetStartedNowSectionProps {
  title: string;
  body: string;
  logosHeading: string;
  logos: Array<{ image: IImage }>;
  successMessage: {
    title: string;
    body: string;
  };
}

const GetStartedNowSection: React.FC<GetStartedNowSectionProps> = ({
  title,
  body,
  logosHeading,
  logos,
  successMessage,
}) => (
  <div className="overflow-hidden bg-tertiary">
    <section className="py-16 overflow-visible md:py-32 u-container ">
      <h2 className="mb-8 text-center u-h2 md:mb-14">{title}</h2>

      {/* <div className="max-w-3xl px-16 pt-10 pb-12 mx-auto bg-white rounded-lg lg:px-32 lg:pt-20 lg:pb-24 shadow-contact-form">
      <p className="text-lg font-semibold leading-8 mb-11">{body}</p>
    </div> */}
      <div className="max-w-3xl mx-auto mb-24">
        <GetInTouchForm
          body={body}
          hasTitle={false}
          successTitle={successMessage?.title}
          successBody={successMessage?.body}
        />
      </div>

      <div>
        <h3 className="text-lg font-semibold text-center mb-15">
          {logosHeading}
        </h3>

        <div className="flex flex-wrap items-center justify-center lg:flex-nowrap lg:space-x-20">
          {logos.map(({ image: { sourceUrl, altText } }, index) => (
            <div
              key={index}
              className="flex items-center justify-center w-1/2 mb-10 lg:w-auto max-w-none lg:mb-0"
            >
              <img
                src={sourceUrl}
                alt={altText}
                className="flex-grow-0 flex-shrink-0 w-auto h-7 lg:h-10 max-w-none"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  </div>
);

export default GetStartedNowSection;
