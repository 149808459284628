import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import BenefitSection from '../components/organisms/BenefitSection';
import CreativeFreedomSection from '../components/organisms/CreativeFreedomSection';
import GetStartedNowSection from '../components/organisms/GetStartedNowSection';
import LeadingBenefitsSection from '../components/organisms/LeadingBenefitsSection';
import TwitterHero from '../components/organisms/TwitterHero';

const TwitterPage = ({ data: { page: data } }) => (
  <Layout>
    <Seo post={data} />

    <TwitterHero {...data.twitter.twitterHero} />
    <LeadingBenefitsSection {...data.twitter.twitterLeadingBenefitsSection} />
    {data.twitter.twitterBenefits.map((benefit, index) => (
      <BenefitSection
        {...benefit}
        key={index}
        imageSide={index % 2 ? 'right' : 'left'}
      />
    ))}
    <CreativeFreedomSection
      {...data.twitter.howItWorksSection}
      backgroundImage="/images/twitter-how-it-works-bg.jpg"
    />
    <GetStartedNowSection
      {...{
        ...data.twitter.getStartedForm,
        successMessage: data.twitter.twitterSuccessMessage,
      }}
    />
  </Layout>
);

export const pageQuery = graphql`
  query TwitterQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      slug
      ...SEO
      twitter {
        twitterHero {
          title
          body
        }
        twitterLeadingBenefitsSection {
          lead
          title
          tiles {
            icon {
              ...Image
            }
            title
            body
          }
        }
        twitterBenefits {
          title
          body
          image {
            ...Image
          }
        }
        howItWorksSection {
          title
          body
          image {
            ...Image
          }
        }
        getStartedForm {
          title
          body
          logosHeading
          logos {
            image {
              ...Image
            }
          }
          twitterSuccessMessage {
            title
            body
          }
        }
      }
    }
  }
`;

export default TwitterPage;
